*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: monospace;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(25px + 2vmin);
  color: #1ec50f;
}

.App-link {
  color: #61dafb;
}
